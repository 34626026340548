import React, { Component } from "react";
import "./css/intro.css";
import {
  WebGLRenderer,
  Vector2,
  Clock,
  PerspectiveCamera,
  Scene,
  Vector3,
  PlaneGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  TextureLoader,
  Raycaster,
  LoadingManager,
  VideoTexture,
  FrontSide,
} from "three";
import FlyScene from "./objects/Scene";

const TWEEN = require("@tweenjs/tween.js");
class Intro extends Component {
  componentDidMount() {
    var container = this.mount;
    container.className = "container";

    //Loader code
    var progress = document.createElement("div");
    progress.className = "container";
    // var progressBar = document.createElement("div");
    // progressBar.className = "loading";
    // progress.appendChild(progressBar);
    // var progressBarFill = document.createElement("div");
    // progressBarFill.className = "loadingfill";
    // progressBar.appendChild(progressBarFill);

    let spinner = document.createElement("div");
    spinner.className = "spinner";
    progress.appendChild(spinner);
    container.appendChild(progress);

    var manager = new LoadingManager();
    manager.onLoad = function () {
      setTimeout(() => {
        console.log("Loading complete!");
        container.removeChild(progress);
        //Intro logo animation
        var tweenDrop = new TWEEN.Tween(logoVidMaterial)
          .to({ opacity: 1 }, 2000)
          .easing(TWEEN.Easing.Quadratic.Out)
          .onStart(() => {
            logoVid.play();
            new TWEEN.Tween(logoVidMesh.scale).to(new Vector3(0.67, 0.67, 0.67), 5000).start();
          })
          .onComplete(() => {
            new TWEEN.Tween(logoVidMaterial).to({ opacity: 0 }, 2000).start();
          });

        tweenDrop.start();

        // render loop
        var clock = new Clock();
        var speed = 10; //units a second
        var delta = 0;
        const onAnimationFrameHandler = (timeStamp) => {
          delta = clock.getDelta();
          if (mouseMoved) {
            camera.position.set(
              camera.position.x - (camera.position.x - (cameraStartingMouse.x + mouseDiff.x)) * (delta * speed),
              camera.position.y - (camera.position.y - (cameraStartingMouse.y + mouseDiff.y)) * (delta * speed),
              cameraDist
            );
          }
          var pos = camera.position;
          logoVidMesh.position.set(pos.x, pos.y, pos.z - 1000);
          // stats.update()
          renderer.render(scene, camera);
          flyScene.update && flyScene.update(timeStamp);
          window.requestAnimationFrame(onAnimationFrameHandler);
        };
        window.requestAnimationFrame(onAnimationFrameHandler);

        container.addEventListener("mousemove", onMouseMove, false);
        container.addEventListener("mousedown", onMouseDown, false);
        container.addEventListener("mouseup", onMouseUp, false);

        container.addEventListener("touchmove", onTouchMove, false);
        container.addEventListener("touchstart", onTouchDown, false);
        container.addEventListener("touchend", onTouchUp, false);
        container.addEventListener("touchcancel", onTouchUp, false);

        setTimeout(() => {
          container.appendChild(footerContainer);
          footerContainer.className += " fade";
        }, 6000);

        flyScene.play();
      }, 1000);
    };
    manager.onStart = function (url, itemsLoaded, itemsTotal) {
      console.log("Started loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");
    };
    // manager.onProgress = function (item, loaded, total) {
    //   console.log("progress: ", (loaded / total) * 100 + "%");
    //   console.log(progressBar);
    //   // progressBar.style.width = (loaded / total * 100) + '%'
    //   progressBarFill.style.width = (loaded / total) * 100 + "%";
    // };

    const scene = new Scene();
    const camera = new PerspectiveCamera(90, 1.3333, 0.1, 2000);
    var canvas = document.createElement("canvas");
    container.appendChild(canvas);
    const renderer = new WebGLRenderer({
      antialias: false,
      canvas: canvas,
    });
    const flyScene = new FlyScene(manager);
    const cameraDist = document.body.clientWidth > 500 ? 880 : 1000;
    flyScene.scale.set(1.25, 1.25, 1);
    scene.add(flyScene);
    camera.position.set(0, 0, cameraDist);
    camera.lookAt(new Vector3(0, 0, 0));

    // static logo
    // var logo = new Object3D();
    // const loader = new TextureLoader(manager);
    // const geometry = new PlaneGeometry(803, 65, 1, 1);
    // logo.material = new MeshBasicMaterial({ map: loader.load("/intro/Logo.png") });
    // var logoMesh = new Mesh(geometry, logo.material);
    // logoMesh.scale.set(0.53, 0.53, 1);
    // logo.material.transparent = true;
    // scene.add(logoMesh);
    // logo.material.opacity = 0;

    const logoVid = document.createElement("video");
    logoVid.id = "logovid";
    logoVid.src = "/intro/logo-animated.mp4";
    logoVid.muted = true;
    logoVid.playsInline = true;
    logoVid.autoplay = true;
    const logoVidTexture = new VideoTexture(logoVid);
    const logoVidMaterial = new MeshBasicMaterial({
      map: logoVidTexture,
      side: FrontSide,
      toneMapped: false,
      color: 0xffffff,
    });
    const logoVidGeometry = new PlaneGeometry(803, 65, 1, 1);
    const logoVidMesh = new Mesh(logoVidGeometry, logoVidMaterial);
    scene.add(logoVidMesh);
    logoVidMaterial.opacity = 0;
    // renderer
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0xffffff, 1);

    renderer.domElement.style.zIndex = 1;
    renderer.domElement.style.position = "absolute";
    renderer.domElement.setAttribute("body-scroll-lock-ignore", "body-scroll-lock-ignore");

    // resize
    const windowResizeHanlder = () => {
      const { innerHeight, innerWidth } = window;
      renderer.setSize(innerWidth, innerHeight);
      camera.aspect = innerWidth / innerHeight;
      camera.updateProjectionMatrix();
    };

    windowResizeHanlder();
    window.addEventListener("resize", windowResizeHanlder);

    // dom
    document.body.style.margin = 0;
    container.appendChild(renderer.domElement);

    //Debug stuff: FPS Counter
    // function createStats() {
    //   var stats = new Stats()
    //   stats.setMode(0)

    //   stats.domElement.style.position = 'absolute'
    //   stats.domElement.style.left = '0'
    //   stats.domElement.style.top = '0'

    //   return stats
    // }

    // const stats = createStats()
    // container.appendChild(stats.domElement)
    /////////////////////

    var mouseStart = new Vector2();
    var mouseDiff = new Vector2();
    var cameraStartingMouse = new Vector2();
    var mouseDown = false;
    var mouseMoved = false;

    var raycaster = new Raycaster();
    var mouse = new Vector2();

    function onMouseMove(event) {
      event.preventDefault();
      if (mouseDown) {
        mouseMoved = true;
        // var mouseMove = new Vector2()
        var currentX = (((event.clientX / window.innerWidth) * 2 - 1) * window.innerWidth) / 2;
        var currentY = ((-(event.clientY / window.innerHeight) * 2 + 1) * window.innerHeight) / 2;

        mouseDiff.x = mouseStart.x - currentX;
        mouseDiff.y = mouseStart.y - currentY;
      }
    }
    function onMouseDown(event) {
      console.log("OnMouseDown");
      mouseStart.x = (((event.clientX / window.innerWidth) * 2 - 1) * window.innerWidth) / 2;
      mouseStart.y = ((-(event.clientY / window.innerHeight) * 2 + 1) * window.innerHeight) / 2;
      mouseDown = true;
      cameraStartingMouse.x = camera.position.x;
      cameraStartingMouse.y = camera.position.y;
    }
    function onMouseUp(event) {
      console.log("OnMouseUp");
      if (mouseMoved === false) {
        console.log("true");
        //For tracking taps on assets
        mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        var testObjects = [];
        flyScene.children.forEach((el) => testObjects.push(el.children[0]));
        var intersects = raycaster.intersectObjects(testObjects);
        if (intersects.length > 0) {
          intersects[0].object.callback();
        }
      }
      mouseDown = false;
      mouseMoved = false;
    }

    function onTouchMove(event) {
      event.preventDefault();
      var currentX = (((event.touches[0].clientX / window.innerWidth) * 2 - 1) * window.innerWidth) / 2;
      var currentY = ((-(event.touches[0].clientY / window.innerHeight) * 2 + 1) * window.innerHeight) / 2;
      if ((mouseDown && Math.abs(mouseStart.x - currentX) > 15.0) || Math.abs(mouseStart.y - currentY) > 15.0) {
        // var mouseMove = new Vector2()
        mouseMoved = true;
        mouseDiff.x = (mouseStart.x - currentX) * 2;
        mouseDiff.y = (mouseStart.y - currentY) * 2;
      }
    }
    function onTouchDown(event) {
      event.preventDefault();
      mouseStart.x = (((event.touches[0].clientX / window.innerWidth) * 2 - 1) * window.innerWidth) / 2;
      mouseStart.y = ((-(event.touches[0].clientY / window.innerHeight) * 2 + 1) * window.innerHeight) / 2;
      mouseDown = true;
      cameraStartingMouse.x = camera.position.x;
      cameraStartingMouse.y = camera.position.y;
    }
    function onTouchUp(event) {
      event.preventDefault();
      if (mouseMoved === false) {
        //For tracking taps on assets
        mouse.x = (event.changedTouches[0].clientX / renderer.domElement.clientWidth) * 2 - 1;
        mouse.y = -(event.changedTouches[0].clientY / renderer.domElement.clientHeight) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        var testObjects = [];

        flyScene.children.forEach((el) => testObjects.push(el.children[0]));
        var intersects = raycaster.intersectObjects(testObjects);
        if (intersects.length > 0) {
          intersects[0].object.callback();
        }
      }

      cameraStartingMouse.x = 0;
      cameraStartingMouse.y = 0;
      mouseStart.x = 0;
      mouseStart.y = 0;
      mouseDiff.x = 0;
      mouseDiff.y = 0;

      mouseDown = false;
      mouseMoved = false;
    }

    var footerContainer = document.createElement("div");
    var footer = document.createElement("div");
    if (document.body.clientWidth > 501) {
      footerContainer.className = "footerContainer";
      footer.className = "footer";
    } else {
      footerContainer.className = "footerContainerMobile";
      footer.className = "footerMobile";
    }

    footerContainer.appendChild(footer);
    footer.innerHTML =
      '<span class="footerText"><a href="/we/">We</a> create <a href="/physical/">physical</a>, <a href="/digital/">digital</a>, and <a href="/hybrid/">hybrid</a> experiences for the world we live in, and the devices we live on.</span>';

    footer.addEventListener("mousedown", onFooterMouseDown, false);
    footer.addEventListener("click", onFooterMouseClick, false);
    footer.addEventListener("mouseup", onFooterMouseUp, false);

    footer.addEventListener("touchstart", onFooterTouchDown, false);
    footer.addEventListener("touchend", onFooterTouchUp, false);
    footer.addEventListener("touchcancel", onFooterTouchUp, false);
    function onFooterMouseClick(e) {
      e.stopPropagation();
    }
    function onFooterMouseDown(e) {
      e.stopPropagation();
    }
    function onFooterMouseUp(e) {
      e.stopPropagation();
    }
    function onFooterTouchDown(e) {
      e.stopPropagation();
    }
    function onFooterTouchUp(e) {
      e.stopPropagation();
    }
  }
  render() {
    return (
      <div className="intro-wrap">
        <div ref={(el) => (this.mount = el)} />
      </div>
    );
  }
}
export default Intro;
