import { PlaneGeometry, MeshBasicMaterial, Mesh, Group, TextureLoader, VideoTexture } from "three";
const TWEEN = require("@tweenjs/tween.js");
// import TWEEN from "tweenjs/lib/tweenjs";

export default class Panel extends Group {
  constructor(
    manager,
    name = "panel",
    path,
    type = "static",
    width = 720,
    height = 480,
    startingX = 0,
    startingY = 0,
    startingZ = 999,
    scaleX = 1,
    scaleY = 1,
    renderOrder = 1,
    url = "https://www.google.com"
  ) {
    super();

    // this.order = order
    this.isTweening = false;
    this.startX = startingX;
    this.startY = startingY;
    this.startZ = startingZ;

    const loader = new TextureLoader(manager);
    const geometry = new PlaneGeometry(width, height, 1, 1);

    // geometry.translate(0, 0, order*10)
    // geometry.translate(anchorX, anchorY, 0)

    if (type === "video") {
      this.vid = document.createElement("VIDEO");
      this.vid.setAttribute("src", path);
      this.vid.muted = true;
      document.body.appendChild(this.vid);
      this.vid.autoplay = true;
      
      // this.vid.style.zIndex = -1
      this.vid.style.position = "absolute";
      this.vid.style.zIndex = 0;
      this.vid.style.maxWidth = "50%";
      this.vid.loop = true;
      this.vid.playsInline = true;
      this.vid.muted = true;
      this.vid.play();
      const texture = new VideoTexture(this.vid);
      this.material = new MeshBasicMaterial({ map: texture });
    } else {
      this.material = new MeshBasicMaterial({ map: loader.load(path) });
    }
    this.material.transparent = true;
    this.material.opacity = 0.5;
    this.material.depthTest = false;
    this.mesh = new Mesh(geometry, this.material);
    this.mesh.callback = function () {
      window.open(this.url, "_self");
    };
    this.mesh.url = url;
    this.mesh.scale.set(scaleX, scaleY, 1);
    this.mesh.renderOrder = renderOrder;

    this.add(this.mesh);
    this.visible = false;
    this.name = name;
  }
  update(timeStamp) {
    // this.position.set(timeStamp/1000, timeStamp/100, 0);
    // this.rotation.x = timeStamp / 10000;
  }
  setZ(z) {
    this.mesh.position.set(this.startX, this.startY, this.startZ + z);
  }
  playIntro(delay = 0, duration = 4000, endingX = 0, endingY = 0, endingZ = 999) {
    if (this.isTweening === true) {
      return;
    }

    setTimeout(() => {
      this.visible = true;
      this.setZ(this.startZ);

      var tweenDrop = new TWEEN.Tween(this.mesh.position)
        .to(
          {
            x: endingX,
            y: endingY,
            z: 0,
          },
          duration
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onStart(() => {
          new TWEEN.Tween(this.material).to({ opacity: 1 }, 2000).start();
          this.isTweening = true;
        })
        .onComplete(() => {
          this.isTweening = false;
        });

      tweenDrop.start();
    }, delay);
  }
}
