import hero from "./assets/WE.png";
import hero2x from "./assets/WE@2x.png";
// import hero3x from "./assets/WE@3x.png";
import diversity from "./assets/diversity.png";
import diversity2x from "./assets/diversity@2x.png";
import diversityGIF from "./assets/diversity.gif"
import icon360 from "./assets/icon-360.svg";
import iconBulb from "./assets/icon-bulb.svg";
import iconCode from "./assets/icon-code.svg";
import iconDesign from "./assets/icon-design.svg";
import iconPeople from "./assets/icon-people.svg";
import iconProduction from "./assets/icon-production.svg";

function We() {
  return (
    <div className="we-wrap">
      <img srcSet={`${hero}, ${hero2x} 2x`} src={hero} alt="WE" className="we-hero" />
      <div className="we-text">
        <div className="heading">
          Our mission is to make <span className="red-heading">cultural content + experiences</span> the most powerful
          marketing tool for brands.
        </div>
        <p>
        At TH experiential we develop content that is culturally compelling, cause-based, and connecting communities and 
        brands over shared values and interests. We design experiences, engagement and 2-way participation to enhance and 
        amplify content to be an even more powerful marketing medium beyond legacy advertising and traditional media. 
          <br />
          <br />
          Since 2006, we have strategically partnered with over 500 brands to activate both physically and virtually. 
          We have executed over 700 campaigns inclusive of mobile tours, pop-ups, conferences, sponsorships, trade shows, 
          festivals, concerts, retail events, installations and more. Developing hybrid, inclusive, cross-platform marketing 
          strategies is empowering our clients to meet their audiences wherever they are: in real life, virtually, and on social.
        </p>
      </div>
      <div className="heading">Our Capabilities</div>
      <div className="icon-grid">
        <div className="icon-grid-item">
          <img src={iconBulb} alt="Experiential Marketing Strategy" />
          Experiential <br /> Marketing 
          Strategy
        </div>
        <div className="icon-grid-item">
          <img src={iconCode} alt="Digital Development" />
          Digital Development
        </div>
        <div className="icon-grid-item">
          <img src={icon360} alt="360 Campaign Development" />
          360 Campaign Development
        </div>
        <div className="icon-grid-item">
          <img src={iconProduction} alt="Production" />
          Production
        </div>
        <div className="icon-grid-item">
          <img src={iconDesign} alt="Design 2D, 3D, UX/UI, AR, CGI" />
          Design 2D, 3D, UX/UI, AR, CGI
        </div>
        <div className="icon-grid-item">
          <img src={iconPeople} alt="Content Studio &amp; Talent Sourcing" />
          Content Studio
          &amp; Talent <br /> Sourcing
        </div>
      </div>
      <div className="heading">Our Team</div>
      <div className="team-grid">
        <div className="team-grid-item team-grid-section">
          <div className="team-grid-item" id="deiw">
            <img srcSet={`${diversity}, ${diversity2x} 2x`} src={diversity} alt="Diversity Equity Inclusion Workspace"/>
            <img src={diversityGIF} alt="Diversity Equity Inclusion Workspace Animation" id="diversity-animation"/>
          </div>

          <div className="team-grid-item" id="team">
            We are DE&I certified and committed to our inclusive values and those of our clients.
          </div>
        </div>
        <div className="team-grid-item team-grid-section">
          <div className="team-grid-item" id="success">
            Our success is 100% fueled by our diverse talented team.
          </div>
          <div className="team-grid-item team-stats">
            <span className="team-percentage">
              100%
            </span>
            <span className="team-percentage-description">
              Female Leadership
            </span>
          </div>

          <div className="team-grid-item team-stats">
            <span className="team-percentage">
              95%
            </span>
            <span className="team-percentage-description">
              Female Workforce
            </span>
          </div>
          <div className="team-grid-item team-stats">
            <span className="team-percentage">
              50%
            </span>
            <span className="team-percentage-description">
              People of Color
            </span>
          </div>
          <div className="team-grid-item team-stats">
            <span className="team-percentage">
              20%
            </span>
            <span className="team-percentage-description">
              Black & African American
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default We;
