import { Group } from "three";
import Panel from "./Panel/Panel.js";
import BasicLights from "./Lights.js";
import assets from "./intro-assets.json";
import assetsMobile from "./intro-assets_mobile.json";
const TWEEN = require("@tweenjs/tween.js");
const offsetX = document.body.clientWidth > 500 ? assets.offsetX : assetsMobile.offsetX;
const offsetY = document.body.clientWidth > 500 ? assets.offsetY : assetsMobile.offsetY;
const factorZ = document.body.clientWidth > 500 ? assets.factorZ : assetsMobile.factorZ;
export default class PanelScene extends Group {
  constructor(manager) {
    super();
    this.meta = [];
    var metadata = document.body.clientWidth > 500 ? assets : assetsMobile;
    metadata.panels.forEach((e) => {
      this.meta.push(e);
      const object = new Panel(
        manager,
        e.name,
        e.path,
        e.type,
        e.width,
        e.height,
        e.x + offsetX,
        (e.y + offsetY) * -1,
        e.z * -1 * factorZ,
        e.scaleX,
        e.scaleY,
        e.renderOrder,
        e.url
      );
      this.add(object);
    });

    const lights = new BasicLights();
    this.add(lights);
  }
  play() {
    const introDelay = 1500;
    this.meta.forEach((e, i) => {
      const object = this.children[i];
      object.playIntro(e.delay + introDelay, e.duration, e.finalX + offsetX, (e.finalY + offsetY) * -1, e.finalZ);
    });
  }
  update(timeStamp) {
    this.children.forEach((e) => {
      if (e.constructor.name === "Panel") e.update(timeStamp);
    });
    TWEEN.update();
  }
}
