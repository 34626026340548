import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import "normalize.css";
import Intro from "./Intro";
import We from "./We";
import Physical from "./Physical";
import Digital from "./Digital";
import Hybrid from "./Hybrid";
import Header from "./components/Header";
import Footer from "./components/Footer";
import projectData from "./components/all-project-data";
import Project from "./Project";

export default function Main() {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  let paths = projectData.map((p) => p.path);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Intro} />
        <Route
          path="*"
          component={() => (
            <section className="content">
              <div className="content-container">
                <Route path="*" render={(props) => <Header {...props} />} />
                <main>
                  <Switch>
                    <Route exact path="/we" component={We} />
                    <Route exact path="/digital" component={Digital} />
                    <Route exact path="/physical" component={Physical} />
                    <Route exact path="/hybrid" component={Hybrid} />
                    <Route exact path={paths} render={(props) => <Project {...props} />} />
                  </Switch>
                </main>
                <Footer />
              </div>
            </section>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}
