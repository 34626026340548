import React from "react";
import { NavLink } from "react-router-dom";
import svgLogo from "../assets/thexperiential-logo-wide.svg";
import allProjectData from "./all-project-data";

export default function Header(props) {
  let proj = allProjectData.find((p) => p.path === props.location.pathname);
  let isDigital = proj && proj.type === "digital";
  let isPhysical = proj && proj.type === "physical";
  let isHybrid = proj && proj.type === "hybrid";
  return (
    <header>
      <div className="header-logo">
        <NavLink to="/">
          <img src={svgLogo} alt="TH Experiential logo" />
        </NavLink>
      </div>
      <div className="header-nav">
        <span>
          <NavLink to="/we">WE</NavLink>
        </span>
        <span>
          <NavLink to="/physical" className={isPhysical ? "active" : ""}>
            Physical
          </NavLink>
        </span>
        <span>
          <NavLink to="/digital" className={isDigital ? "active" : ""}>
            Digital
          </NavLink>
        </span>
        <span>
          <NavLink to="/hybrid" className={isHybrid ? "active" : ""}>
            Hybrid
          </NavLink>
        </span>
      </div>
    </header>
  );
}
