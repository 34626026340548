import React from "react";
import LinkGridItem from "./LinkGridItem";

export default function LinkGrid({ gridData }) {
  return (
    <div className="link-grid-wrap">
      {gridData.map((data, idx) => (
        <LinkGridItem data={data} idxLocation={idx} key={data.path} />
      ))}
    </div>
  );
}
