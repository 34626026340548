import allProjectData from "./components/all-project-data";

function Project(props) {
  
  let proj = allProjectData.find((p) => p.path === props.location.pathname);
  console.log(proj)
  let allPics = [...proj.gallery];
  let featured = allPics.shift();
  return (
    <>
      <div className="proj-wrap">
        <div className="proj-m-featured">
          {!featured.type || featured.type !== "vimeo" ? (
            <img src={featured.url} alt={`${proj.client} ${proj.projectName} featured`} />
          ) : (
            <>
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src={featured.url}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                  title={proj.projectName}
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </>
          )}
        </div>
        <div className="proj-header-wrap">
          <div className="proj-header">
            <span className="proj-client">{proj.client}</span> <span className="proj-name">{proj.projectName}</span>
          </div>
        </div>
        <div className="proj-hero">
          <div className="proj-featured">
            {!featured.type || featured.type !== "vimeo" ? (
              <img src={featured.url} alt={`${proj.client} ${proj.projectName} featured`} />
            ) : (
              <>
                {/* <div style={{ padding: "56.25% 0 0 0", position: "relative" }}> */}
                <div class='embed-container' style={{
                  position: "relative",
                  paddingBottom: `calc( ${featured.customVideoHeight ? featured.customVideoHeight : 1080 } / ${featured.customVideoWidth ? featured.customVideoWidth : 1920 } * 100%)`,
                  overflow: "hidden",
                  maxWidth: "100%",
                  background: "black"
                }}>
                  <iframe
                    src={featured.url}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    title={proj.projectName}
                  ></iframe>
                  </div>
                {/* </div> */}
                <script src="https://player.vimeo.com/api/player.js"></script>
              </>
            )}
          </div>
          <div className="proj-text" dangerouslySetInnerHTML={{ __html: proj.description }} />
        </div>
        {/* WORKING statbar if needed */}
        {/* {proj.statBar && (
          <div className="proj-stats">
            {proj.statBar.map((s) => (
              <div className="stat">
                <div className="stat-num">{s.statNumber}</div>
                <div className="stat-name">{s.statName}</div>
              </div>
            ))}
          </div>
        )} */}
        {proj.gallery && proj.type !== 'digital' && (
          <div className="gallery">
            {allPics.map((pic) => (
              <div className={`gallery-item ${pic.sizeClass}`}>
                <img src={pic.url} alt={pic.alt} />
                {
                  pic.subtitle && (
                  <span className={`gallery-subtitle`}>{pic.subtitle}</span>
                  )
                }
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Project;
