import React from "react";
import insta from "../assets/icon-instagram.svg";
import linkedin from "../assets/icon-linkedin.svg";

export default function Footer(props) {
  return (
    <footer>
      <div className="footer-wrap">
        <div className="footer-item">
          <a href="mailto:info@thexperiential.com">Request Info</a>
        </div>
        <div className="footer-item">
          <a href="mailto:info@thexperiential.com">Work with us</a>
        </div>
        <div className="footer-item">
          Follow us
          <a
            href="https://www.linkedin.com/company/thexperiential/"
            className="footer-social-icon li"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="TH Experiential on LinkedIn" />
          </a>
          <a
            href="https://www.instagram.com/th_experiential/"
            className="footer-social-icon ig"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="TH Experiential on Instagram" />
          </a>
        </div>
        <div className="footer-item mobile-social">
          <a
            href="https://www.linkedin.com/company/thexperiential/"
            className="footer-social-icon li"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="TH Experiential on LinkedIn" />
          </a>
          <a
            href="https://www.instagram.com/th_experiential/"
            className="footer-social-icon ig"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="TH Experiential on Instagram" />
          </a>
        </div>
      </div>
    </footer>
  );
}
