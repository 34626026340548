import React from "react";
import { NavLink } from "react-router-dom";

export default function LinkGridItem({ data, idxLocation }) {
  let isEndOfThreeRow = idxLocation % 3 === 2;
  let isEndofTwoRow = idxLocation % 2 === 1;
  let className = "link-grid-item";

  if (isEndOfThreeRow) {
    className += " link-grid-item-end-3";
  }

  if (isEndofTwoRow) {
    className += " link-grid-item-end-2";
  }

  return (
    <div className={className}>
      <img src={data.thumbnailUrl} alt={data.projectName} />
      <div className="item-overlay">
        <NavLink to={data.path}>
          <span className="item-line"></span>
          {data.client}
          <br />
          {data.projectName}
        </NavLink>
      </div>
    </div>
  );
}
